





























import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';

@Component({})
export default class FooterNew extends Vue {
  get isPerformer() {
    return vxm.user.data.role === 'performer';
  }
}
