





































































































































import {Component, Mixins, Vue} from 'vue-property-decorator';
import CroppieMixin from '@/mixins/CroppieMixin';
import Modal from '@/components/Modal.vue';
import Vuelidate from 'vuelidate';
import {email, helpers, minLength, required, sameAs} from 'vuelidate/lib/validators';
import {vxm} from '@/store';
import BlobToFileMixin from '@/mixins/BlobToFileMixin';
import {GendersEnum} from '@/types/enums/GendersEnum';

Vue.use(Vuelidate);

@Component({
  components: {
    Modal,
  },
  validations: {
    form1: {
      name: {required},
      email: {required, email},
      password: {
        required,
        minLength: minLength(8),
        alpha: helpers.regex('alpha', /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/),
      },
      confirmPassword: {required, sameAs: sameAs('password')},
    },
  },
})
export default class FanRegistration extends Mixins(CroppieMixin, BlobToFileMixin) {
  showPass = true;
  showPass2 = true;
  // infant = false;
  regStep = 1;
  steps = 2;
  form1 = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  };
  requestSend = false;
  /*
  Phase 2 component, return later
  form3 = {
    gender: '',
    preferences: '',
  };
  form4 = {
    photo: '',
  };
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  content_types = ['bisexual', 'straight', 'gay'];
  */
  genders = GendersEnum;
  resendEmailToken = '';
  emailError = false;

  /*
  get years() {
    const year = new Date().getFullYear();
    return Array.from({length: year - 1950}, (value, index) => 1951 + index);
  }
  */
  stepController() {
    if (this.regStep === 1) {
      this.checkEmail();
      return;
    }

    /*
    if (this.regStep === 2 && !this.cropBlock) {
      this.submitReg();
    } else if (this.regStep === 2 && this.cropBlock) {
      this.crop();
    } else {
      this.goToNextStep(this.regStep);
    }
    */
  }

  /*
  detectAge() {
    const selectedDate = this.form1.dateOfBirth.day + this.form1.dateOfBirth.month + this.form1.dateOfBirth.year;
    const birthday = moment(selectedDate, 'D MMMM YYYY');
    const age = moment.duration(moment().diff(birthday)).asYears();
    if (this.form1.dateOfBirth.month !== '') {
      this.activeMonth = true;
    }
    if (this.form1.dateOfBirth.day !== '') {
      this.activeDay = true;
    }
    if (this.form1.dateOfBirth.year !== '') {
      this.activeYear = true;
    }
    if (
      this.form1.dateOfBirth.day !== '' &&
      this.form1.dateOfBirth.month !== '' &&
      this.form1.dateOfBirth.year !== ''
    ) {
      this.infant = age < 18;
    }
  }
  */
  mounted() {
    this.$gtag.event('sign_up', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: 'fan',
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_label: 'start',
    });
  }

  goToPrevStep() {
    if (this.regStep === 1) {
      this.$emit('closeModal');
    }
    if (this.regStep !== 1) {
      this.regStep--;
    }
  }

  goToNextStep(regStep) {
    const form = 'form' + regStep;
    this.$v[form].$touch();
    if (!this.$v[form].$invalid) {
      this.regStep++;
    }
  }
  submitReg() {
    this.requestSend = true;
    const data = new FormData();
    data.append('email', this.form1.email);
    data.append('password', this.form1.password);
    data.append('name', this.form1.name.trim());
    data.append('emailVerificationCallbackUrl', `${process.env.VUE_APP_CONFIRM_EMAIL_URL}`);

    // data.append('emailVerificationCallbackUrl', `${window.location.origin}/confirm-email`);

    /*
    data.append('phone', this.form1.phone);
    data.append(
      'dob',
      moment(`${this.form1.dateOfBirth.month} ${this.form1.dateOfBirth.day} ${this.form1.dateOfBirth.year}`).format(
        'YYYY-MM-DD',
      ),
    );
    data.append('sex', this.form3.gender);
    data.append('sexualPreference', this.form3.preferences);
    if (this.croppieImage) {
      data.append('file', this.dataURLtoFile(this.croppieImage, 'avatar'));
    }
    */

    vxm.user
      .registerFan(data)
      .then((res) => {
        this.$gtag.event('sign_up', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          event_category: 'fan',
          // eslint-disable-next-line @typescript-eslint/camelcase
          event_label: 'complete',
        });
        this.resendEmailToken = res.data.resendConfirmEmailToken;
        this.regStep++;
        this.requestSend = false;
      })
      .catch((error) => {
        this.requestSend = false;
        return error;
      });
  }

  resendConfirmEmail() {
    if (this.resendEmailToken) {
      vxm.user.resendConfirmEmail(this.resendEmailToken).catch((error) => {
        return error;
      });
    }
  }

  checkEmail() {
    vxm.user
      .checkEmail(this.form1.email)
      .then(() => {
        this.submitReg();
      })
      .catch(() => {
        this.emailError = true;
      });
  }
}
