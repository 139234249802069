




















































































































import {Component, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Vuelidate from 'vuelidate';
import VueRecaptcha from 'vue-recaptcha';
import {email, minLength, required} from 'vuelidate/lib/validators';
import FanRegistration from '@/components/modals/FanRegistration.vue';
import ModelRegistration from '@/components/modals/ModelRegistration.vue';
import {vxm} from '@/store';
import {AuthInterface} from '@/types/authInterface';
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal.vue';
import BecomeModel from '@/components/modals/BecomeModel.vue';
import ServiceUnavailable from '@/components/ServiceUnavailable.vue';
import FooterNew from '@/components/FooterNew.vue';
// Vue.component('vue-recaptcha', VueRecaptcha);

Vue.use(Vuelidate);

@Component({
  components: {
    BecomeModel,
    ForgotPasswordModal,
    Modal,
    VueRecaptcha,
    FanRegistration,
    ModelRegistration,
    ServiceUnavailable,
    FooterNew,
  },
  validations: {
    form: {
      email: {required, email},
      password: {
        required,
        minLength: minLength(8),
        regex(value) {
          return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/.test(value);
        },
      },
    },
  },
})
export default class Login extends Vue {
  isFanLoggedIn = false;
  isModelLoggedIn = false;
  fanRegistration = false;
  modelRegistration = false;
  recaptchaVerified = false;
  pleaseTickRecaptchaMessage = '';
  showPass = true;
  loginError = false;
  captchError = false;
  robot = false;
  toManyRequests = false;
  requestSend = false;
  showForgotPassword = false;
  isVerified = false;
  // recaptchaToken = '';
  form: AuthInterface = {
    email: '',
    password: '',
    captchaToken: '',
  };
  isMobile = window.innerWidth <= 823;
  data() {
    return {
      recaptchaSiteKey: '6LdqyIopAAAAAORwJsQkR5iY1UdvmXeMXk9AGJnn',
      // isVerified: false,
    };
  }
  // formData: {
  //   recaptchaSiteKey: '6LfciolpAAAAAL6keCzwcCyBYtKPNXa4_LFU3W3l';
  //   isVerified: false;
  // };

  mounted() {
    window.addEventListener('resize', this.onResize);

    if (this.$route.query.fanRegistration === 'true') {
      this.fanRegistration = true;
    }

    if (this.$route.query.modelRegistration === 'true') {
      this.modelRegistration = true;
    }
  }
  onVerify(response) {
    this.form.captchaToken = response;
    this.isVerified = true;
  }
  toggleFanLogin(): void {
    this.isModelLoggedIn = false;
    this.isFanLoggedIn = !this.isFanLoggedIn;
    this.$nextTick(() => {
      (this.$refs.fanEmail as HTMLInputElement).focus();
    });
  }
  toggleModelLogin(): void {
    this.isFanLoggedIn = false;
    this.isModelLoggedIn = !this.isModelLoggedIn;
    this.$nextTick(() => {
      (this.$refs.modelEmail as HTMLInputElement).focus();
    });
  }

  toggleFanRegistration(): void {
    this.fanRegistration = true;
    this.isModelLoggedIn = false;
    this.isFanLoggedIn = false;
  }

  toggleModelRegistration(): void {
    this.modelRegistration = true;
    this.isModelLoggedIn = false;
    this.isFanLoggedIn = false;
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  login() {
    if (event) {
      event.preventDefault();
    }
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.requestSend = true;
    if (this.isVerified) {
      vxm.user
        .auth(this.form)
        .then(() => {
          vxm.user
            .getMe()
            .then((res) => {
              if (res.status === 200) {
                if (vxm.user.role === 'user') {
                  vxm.user.paymentCards().catch((error) => {
                    return error;
                  });
                }
                if (vxm.user.inputLink) {
                  this.$router.push(vxm.user.inputLink);
                  vxm.user.inputLink = '';
                  return;
                }
                this.$gtag.event('login', {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  event_category: vxm.user.role === 'user' ? 'fan' : 'model',
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  event_label: vxm.user.name,
                });
                this.$socket.io.opts.query.token = vxm.user.token;
                this.$socket.connect();
                this.$router.push(vxm.user.role === 'user' ? '/fan' : '/model');
              }
            })
            .catch((error) => {
              this.requestSend = false;
              return error;
            });
        })
        .catch((error) => {
          this.requestSend = false;
          if (error.message === 'Network Error') {
            Vue.notify({
              group: 'foo',
              title: 'No Internet connection.',
              duration: 3000,
              type: 'error',
              text: 'Check your network settings and try again.',
            });
            return Promise.reject(error);
          }
          if (error.response.status === 404) {
            this.loginError = true;
          }
          if (error.response.status === 429) {
            this.toManyRequests = true;
          }
          return Promise.reject(error);
        });
    } else {
      this.captchError = true;
      this.requestSend = false;
    }
  }

  onResize() {
    this.isMobile = window.innerWidth <= 823;
  }

  openForgotPassword() {
    this.$emit('openForgotPassword');
  }
}
